import React, {useContext} from 'react'
import MultiBarChart from '../charts/MultiBarChart.jsx'
import PieChart from '../charts/PieChart.jsx'
import { DataContext } from '../contexts/DataContext.jsx'
import { processData, calculateSumByBranch, extractLabels } from '../scripts/functions'

const ReportChartBar = ({ colourScale }) => {
  const { state } = useContext(DataContext);
  const { filteredSalesData } = state;

  // MultiChartBar Specific Logic
  const mbcData = processData(filteredSalesData);
  const mbcTitle = "Sales (Month on Month)";
  const mbcMargin = { top: 20, right: 30, bottom: 20, left: 30 };
  const mbcPadding = { main: 0.1, group: 0.025 };

  // PieChart Specific Logic
  const pieData = calculateSumByBranch(filteredSalesData);
  const key = extractLabels(pieData);
  const PChartTitle = "Sales Comparison by Branch";
  const PChartMargin = 5;
  const PChartOffSet = 100;

  return (
    <div className='reportchartbar'>
      <MultiBarChart
       title={mbcTitle}
       data={mbcData}
       margin={mbcMargin}
       padding={mbcPadding}
       colourLegend="color-legend-topleft"
       legendItem="legend-item-left"
       legendLabel="legend-label"
       colourScale={colourScale}
       backgroundColour="#A0D1FF59"
      />
      <PieChart
        title={PChartTitle}
        data={pieData}
        colourScale={colourScale}
        keys={key}
        colourLegend="color-legend-right"
        legendItem="legend-item-right"
        margin={PChartMargin}
        offset={PChartOffSet}
        backgroundColour="#F0E19959"
      />
    </div>
  )
}

export default ReportChartBar