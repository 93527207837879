import axios from "axios";

const Api = {
  login: "http://localhost:6335/evergreen/login",
  salesData: "http://localhost:6335/evergreen/salesdata",
  stockData: "http://localhost:6335/evergreen/stockdata",
  salesTarget: "http://localhost:6335/evergreen/salestarget",
};

const prodApi = {
  login: "https://data.6334a.com/evergreen/login",
  salesData: "https://data.6334a.com/evergreen/salesdata",
  stockData: "https://data.6334a.com/evergreen/stockdata",
  salesTarget: "https://data.6334a.com/evergreen/salestarget",
  user: "https://data.6334a.com/evergreen/user",
};

const api = axios.create({
  baseURL: "http://localhost:6335/evergreen",
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["authorization"];
  }
};

export { prodApi, setAuthToken };
