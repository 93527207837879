import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext.jsx";
import axios from "axios";
import { prodApi } from "../data/api.js";

const loginUrl = prodApi.login;
const userUrl = prodApi.user;

const LoginModal = () => {
  const { loggedIn, setLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  if (error) {
    console.log(error);
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      // Send login request to the backend
      const tokenResponse = await axios.post(loginUrl, { username, password });
      const { token } = tokenResponse.data;

      // Store the token in local storage or a cookie
      localStorage.setItem("token", token);

      // Get User Info with Token
      const userResponse = await axios.get(userUrl, {
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        withCredentials: "Include",
      });

      // Store user data in local storage
      localStorage.setItem("user", JSON.stringify(userResponse.data));

      // Perform login logic
      setLoggedIn(true);
      setIsLoggedIn(userResponse.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid username or password.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      console.error("e no dey work", error);
    }
  };

  const handleChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  if (loggedIn) {
    return null; // If user is logged in, do not show the modal
  }

  return (
    <>
      <div className="loginfilm"></div>
      <div
        className="login"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="logincontainer">
          <div className="logintitle">
            <h1>LOGIN</h1>
          </div>
          <form className="loginform" onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Username"
              value={username}
              onChange={handleChangeUsername}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handleChangePassword}
            />
            <button type="submit">ENTER</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
