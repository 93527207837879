import React from 'react'
import CardTitle from '../components/CardTitle.jsx'

const ReportTitleBar = () => {
    const title1 = "SALES TARGET"
    const title2 = "PRODUCTS"
    const title3 = "DISTRIBUTORS"
    const title4 = "INVENTORY"
      return (
          <div className="reporttitlebar">
            <CardTitle cardTitle={title1}/>
            <CardTitle cardTitle={title2}/>
            <CardTitle cardTitle={title3}/>
            <CardTitle cardTitle={title4}/>
          </div>
        );
}

export default ReportTitleBar