import React from "react";

const Card = ({ value, subtitle, backgroundColor }) => {
  return (
    <div
      className="card"
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
    >
      <h1 className="value">{value}</h1>
      <p className="subtitle">{subtitle}</p>
    </div>
  );
};

export default Card;
