import React, { createContext, useReducer } from "react";

const initialState = {
  salesData: [],
  stockData: [],
  targetData: [],

  filteredSalesData: [],
  filteredSalesTarget: [],
  filteredStockData: [],
  salesEstimateData: [],
  productSelectFilter: [],
  distributorSelectFilter: [],
};

export const DataContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "SALES_DATA":
      return {
        ...state,
        salesData: action.payload,
      };
    case "STOCK_DATA":
      return {
        ...state,
        stockData: action.payload,
      };
    case "TARGET_DATA":
      return {
        ...state,
        targetData: action.payload,
      };
  
  // Filtered Data
    case "UPDATE_FILTERED_SALES_DATA":
      return {
        ...state,
        filteredSalesData: action.payload,
      };
    case "UPDATE_FILTERED_SALESTARGET_DATA":
      return {
        ...state,
        filteredSalesTarget: action.payload,
      };
    case "UPDATE_FILTERED_STOCK_DATA":
      return {
        ...state,
        filteredStockData: action.payload,
      };
    case "UPDATE_FILTERED_ESTIMATE_DATA":
      return {
        ...state,
        salesEstimateData: action.payload,
      };
    case "UPDATE_FILTERED_PRODUCT_DATA":
      return {
        ...state,
        productSelectFilter: action.payload,
      };
    case "UPDATE_FILTERED_DISTRIBUTOR_DATA":
      return {
        ...state,
        distributorSelectFilter: action.payload,
      };
    default:
      return state;
  }
};

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};
