import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext.jsx";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { LoadingProvider } from "./contexts/LoadingContext.jsx";
import { colourScale } from "./data/colourScale.js";
import Header from "./global/Header.jsx";
import Report from "./pages/Report.jsx";
import KPIs from "./pages/KPIs.jsx";
import "../src/styles/index.css";
import "../src/styles/icons.css";

const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <DataProvider>
          <LoadingProvider>
            <BrowserRouter>
              <Header />
              <Routes>
                <Route
                  path="/"
                  element={<Report colourScale={colourScale} />}
                />
                <Route
                  path="/kpis"
                  element={<KPIs colourScale={colourScale} />}
                />
              </Routes>
            </BrowserRouter>
          </LoadingProvider>
        </DataProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
