import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import MultiselectDropdown from "../components/MultiselectDropdown.jsx";
import { getUniqueValues, sortYears, sortMonths, sortAlphabetically } from "../scripts/functions.js";
import ReportData from "../data/ReportData.jsx"

const currentYear = ((new Date()).getFullYear());

const ReportSlicerBar = () => {
  const { dispatch, state } = useContext(DataContext);
  const { salesData, stockData, targetData } = state

  // Selection State
  const [selectedYears, setSelectedYears] = useState([currentYear]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDistributors, setSelectedDistributors] = useState([])

  // Display State
  const [displayYears, setDisplayYears] = useState("");
  const [displayMonths, setDisplayMonths] = useState("");
  const [displayBranches, setDisplayBranches] = useState("");
  const [displayProducts, setDisplayProducts] = useState("");
  const [displayDistributors, setDisplayDistributors] = useState("");

  // Slicers
  const [yearSelectFilter, setYearSelectFilter] = useState([]);
  const [monthSelectFilter, setMonthSelectFilter] = useState([]);
  const [branchSelectFilter, setBranchSelectFilter] = useState([]);
  const [productSelectFilter, setProductSelectFilter] = useState([]);
  const [distributorSelectFilter, setDistributorSelectFilter] = useState([]);

  // FILTERED DATA
  // SALES
  useEffect(() => {
    const newFilteredSalesData = salesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);
      const distributorMatch =
        selectedDistributors.length === 0 ||
        selectedDistributors.includes(row.distributor);

      return (
        yearMatch &&
        monthMatch &&
        branchMatch &&
        productMatch &&
        distributorMatch
      );
    });
    dispatch({
      type: "UPDATE_FILTERED_SALES_DATA",
      payload: newFilteredSalesData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedBranches,
    selectedProducts,
    selectedDistributors,
    salesData,
  ]);

  // SALES TARGET
  useEffect(() => {
    const newFilteredSalesTarget = targetData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);
      return yearMatch && monthMatch && branchMatch && productMatch;
    });
    dispatch({
      type: "UPDATE_FILTERED_SALESTARGET_DATA",
      payload: newFilteredSalesTarget,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedBranches,
    selectedProducts,
    targetData,
  ]);

  // STOCK
  useEffect(() => {
    const newFilteredStockData = stockData.filter((row) => {
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);

      return productMatch;
    });
    dispatch({
      type: "UPDATE_FILTERED_STOCK_DATA",
      payload: newFilteredStockData,
    });
  }, [
    selectedProducts, 
    stockData
  ]);

  // MULTISELECT FILTERS
  // Year
  useEffect(() => {
    const yearFilteredData = salesData.filter((row) => {
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);
      const distributorMatch =
        selectedDistributors.length === 0 ||
        selectedDistributors.includes(row.distributor);

      return monthMatch && branchMatch && productMatch && distributorMatch;
    });

    setYearSelectFilter(yearFilteredData);
  }, [
    selectedMonths,
    selectedBranches,
    selectedProducts,
    selectedDistributors,
    salesData,
  ]);

  // Month
  useEffect(() => {
    const monthFilteredData = salesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);
      const distributorMatch =
        selectedDistributors.length === 0 ||
        selectedDistributors.includes(row.distributor);

      return yearMatch && branchMatch && productMatch && distributorMatch;
    });

    setMonthSelectFilter(monthFilteredData);
  }, [
    selectedYears,
    selectedBranches,
    selectedProducts,
    selectedDistributors,
    salesData,
  ]);

  // Branch
  useEffect(() => {
    const branchFilteredData = salesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);
      const distributorMatch =
        selectedDistributors.length === 0 ||
        selectedDistributors.includes(row.distributor);

      return yearMatch && monthMatch && productMatch && distributorMatch;
    });

    setBranchSelectFilter(branchFilteredData);
  }, [
    selectedYears,
    selectedMonths,
    selectedProducts,
    selectedDistributors,
    salesData,
  ]);

  // Product
  useEffect(() => {
    const productFilteredData = salesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const distributorMatch =
        selectedDistributors.length === 0 ||
        selectedDistributors.includes(row.distributor);

      return yearMatch && monthMatch && branchMatch && distributorMatch;
    });

    setProductSelectFilter(productFilteredData);
    dispatch({
      type: "UPDATE_FILTERED_PRODUCT_DATA",
      payload: productFilteredData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedBranches,
    selectedDistributors,
    salesData,
  ]);

  // Product (Estimate Calculation)
  useEffect(() => {
    const productFilteredEstimate = salesData.filter((row) => {
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);

      return productMatch;
    });

    // setSalesEstimateFilter(productFilteredEstimate);
    dispatch({
      type: "UPDATE_FILTERED_ESTIMATE_DATA",
      payload: productFilteredEstimate,
    });
  }, [
    selectedProducts, 
    salesData, 
  ]);

  // Distributor
  useEffect(() => {
    const distributorFilteredData = salesData.filter((row) => {
      const yearMatch =
        selectedYears.length === 0 ||
        selectedYears.includes(row.year);
      const monthMatch =
        selectedMonths.length === 0 ||
        selectedMonths.includes(row.month);
      const branchMatch =
        selectedBranches.length === 0 ||
        selectedBranches.includes(row.branch);
      const productMatch =
        selectedProducts.length === 0 ||
        selectedProducts.includes(row.product);

      return yearMatch && monthMatch && branchMatch && productMatch;
    });

    setDistributorSelectFilter(distributorFilteredData);
    dispatch({
      type: "UPDATE_FILTERED_DISTRIBUTOR_DATA",
      payload: distributorFilteredData,
    });
  }, [
    selectedYears,
    selectedMonths,
    selectedBranches,
    selectedProducts,
    salesData,
  ]);

  // Select Functionality
  const handleYearSelect = (selectedList) => {
    setSelectedYears(selectedList);
    setDisplayYears(`${selectedList.length} options selected`);
  };

  const handleMonthSelect = (selectedList) => {
    setSelectedMonths(selectedList);
    setDisplayMonths(`${selectedList.length} options selected`);
  };

  const handleBranchSelect = (selectedList) => {
    setSelectedBranches(selectedList);
    setDisplayBranches(`${selectedList.length} options selected`);
  };

  const handleProductSelect = (selectedList) => {
    setSelectedProducts(selectedList);
    setDisplayProducts(`${selectedList.length} options selected`);
  };

  const handleDistributorSelect = (selectedList) => {
    setSelectedDistributors(selectedList);
    setDisplayDistributors(`${selectedList.length} options selected`);
  };

  const SelectedCountDisplay = ({ selectedOptions }) => {
    const selectedCount = selectedOptions.length;
    return <div>{`${selectedCount} options selected`}</div>;
  };

  return (
    <>
    <ReportData />
    <div className="slicerbarReport">
      <MultiselectDropdown
        label="Year"
        id="year"
        placeholder="Year"
        options={sortYears(getUniqueValues(yearSelectFilter, "year"))}
        selectedValues={selectedYears}
        onChange={handleYearSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Month"
        id="month"
        placeholder="Month"
        options={sortMonths(getUniqueValues(monthSelectFilter, "month"))}
        selectedValues={selectedMonths}
        onChange={handleMonthSelect}
        multiselectClass='multiselect-dropdown'
      />
      <MultiselectDropdown
        label="Branch"
        id="branch"
        placeholder="Branch"
        options={sortAlphabetically(getUniqueValues(branchSelectFilter, "branch"))}
        selectedValues={selectedBranches}
        onChange={handleBranchSelect}
        multiselectClass='multiselect-dropdown'
        maxDisplayedItems={2}
      />
      <MultiselectDropdown
        label="Product"
        id="product"
        placeholder="Product"
        options={getUniqueValues(productSelectFilter, "product")}
        selectedValues={selectedProducts}
        onChange={handleProductSelect}
        multiselectClass='multiselect-dropdown'
        maxDisplayedItems={1}
      />
      <MultiselectDropdown
        label="Distributor"
        id="distributor"
        placeholder="Distributor"
        options={sortAlphabetically(getUniqueValues(distributorSelectFilter, "distributor"))}
        selectedValues={selectedDistributors}
        onChange={handleDistributorSelect}
        multiselectClass='multiselect-dropdown'
        maxDisplayedItems={1}
      />
    </div>
    </>
  );
};

export default ReportSlicerBar;