import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import ChartHeader from "../components/ChartHeader.jsx";
import {formatNumberWithCommas} from "../scripts/functions.js"

const BarChart = ({ data, title, margin, maxBars, padding, backgroundColour }) => {
  const svgRef = useRef(null);
  const parentRef = useRef(null);
  const chartData = data;

  const keys = Object.keys(chartData).slice(0, maxBars);
  const values = Object.values(chartData).slice(0, maxBars);

  useEffect(() => {
    if (chartData && svgRef.current) {
      const svg = d3.select(svgRef.current);

      // Parent Container
      const parentElement = parentRef.current;
      const containerWidth = parentElement.clientWidth;
      const containerHeight = parentElement.clientHeight;

      // Define the chart dimensions based on the container width
      const innerWidth = containerWidth * 0.9;
      const innerHeight = containerHeight * 0.8;

      const xScale = d3
        .scaleBand()
        .domain(keys)
        .range([0, innerWidth])
        .padding(padding);

      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(values)])
        .range([innerHeight, 0]);

      // Generate y-axis tick values
      const yAxisTicks = yScale.ticks(5).map((tick) => ({
        value: tick,
        position: yScale(tick),
      }));

      // Guideline
      svg
        .selectAll(".guideline")
        .data(yAxisTicks)
        .join(
          (enter) => enter.append("line").attr("class", "guideline"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("x1", 0)
        .attr("y1", (tickValue) => tickValue.position)
        .attr("x2", innerWidth)
        .attr("y2", (tickValue) => tickValue.position)
        .attr("stroke", "black")
        .attr("stroke-width", 0.5);
      // .attr("stroke-dasharray", "3 1");

      // Bars
      svg
        .selectAll("rect")
        .data(keys)
        .join(
          (enter) => enter.append("rect"),
          (update) => update,
          (exit) => exit.remove()
        )
        .attr("x", (d) => xScale(d))
        .attr("y", (d) => yScale(chartData[d]))
        .attr("width", xScale.bandwidth())
        .attr("height", (d) => innerHeight - yScale(chartData[d]))
        .on("mouseenter", (event, d) => {
          const [x, y] = d3.pointer(event);
          const value = chartData[d];

          // Show tooltip on hover
          const tooltip = svg.append("g").attr("class", "tooltip");

          // Calculate the width and height based on text length
          const labelWidth = d.length * 8; 
          const valueWidth = (value * 100).toFixed(2).length * 8; 
          const maxWidth = Math.max(labelWidth, valueWidth);
          const widthPadding = 20; 
          const rectWidth = maxWidth + widthPadding;
          const rectHeight = 60;

          tooltip
            .append("rect")
            .attr("class", "tooltip-box")
            .attr("x", x - rectWidth / 2) 
            .attr("y", y - rectHeight / 2) 
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", 2.5)
            .attr("ry", 2.5)
            .attr("fill", "rgba(255, 255, 255, 0.9)");

          tooltip
            .append("text")
            .attr("class", "percentage-label")
            .attr("x", x - rectWidth / 2 + 10) 
            .attr("y", y - rectHeight / 2 + 20) 
            .style("text-anchor", "start") 
            .style("font-family", "Arial")
            .style("font-weight", "bold")
            .style("font-size", "14px")
            .text(`${d}`)

          tooltip
          .append("text")
          .attr("class", "percentage-value")
          .attr("x", x - rectWidth / 2 + 10) 
          .attr("y", y + 5) 
          .style("text-anchor", "start") 
          .style("font-family", "Arial")
          .style("font-weight", "normal")
          .style("font-size", "11px") 
          .text(`${formatNumberWithCommas(value)}`);
        })
        .on("mouseleave", () => {
          // Remove tooltip on mouseleave
          svg.select(".tooltip").remove();
        });

      // Add x-axis
      const xAxis = d3.axisBottom(xScale);
      svg
        .select(".x-axis")
        .attr("transform", `translate(0, ${innerHeight})`)
        .call(xAxis)
        .selectAll("text")
        .attr("color", "black");

      // Add y-axis
      const yAxis = d3.axisLeft(yScale).ticks(5);
      svg
        .select(".y-axis")
        .attr("transform", `translate(0, 0)`)
        .call(yAxis)
        .selectAll(".tick line")
        .attr("x1", -5)
        .attr("stroke", "gray");
    }
  }, [chartData, keys, padding, values]);

  useEffect(() => {
    // Function to handle resizing of the chart
    const handleResize = () => {
      if (svgRef.current) {
        const svg = d3.select(svgRef.current);
        const parentElement = parentRef.current;
        const containerWidth = parentElement.clientWidth;

        // Update the chart dimensions based on the new container width
        const innerWidth = containerWidth * 0.9;

        // Update the xScale range and width of the rects
        const xScale = d3
          .scaleBand()
          .domain(keys)
          .range([0, innerWidth])
          .padding(0.1);

        svg
          .selectAll(".guideline")
          .attr("x1", 0)
          .attr("x2", innerWidth);
        // .attr("stroke-dasharray", "3 1");

        svg
          .selectAll("rect")
          .attr("x", (d) => xScale(d))
          .attr("width", xScale.bandwidth());

        const xAxis = d3.axisBottom(xScale);
        svg.select(".x-axis").call(xAxis);
      }
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartData, keys]);

  return (
    <div
      className="bchart"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <ChartHeader title={title} />
      <svg
        ref={parentRef}
        className="chartsvg"
        style={{
          width: "100%",
        }}
      >
        <g className="bars" ref={svgRef}>
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
    </div>
  );
};

export default BarChart;
