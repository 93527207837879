import React from 'react'
import ReportSlicerBar from '../Report/ReportSlicerBar.jsx'
import ReportTitleBar from '../Report/ReportTitleBar.jsx'
import ReportCardBar from '../Report/ReportCardBar.jsx'
import ReportChartBar from '../Report/ReportChartBar.jsx'
import ReportChartBar2 from '../Report/ReportChartBar2.jsx'

const Report = ({colourScale}) => {
  return (
    <div className='reportdashboard'>
      <ReportSlicerBar />
      <ReportTitleBar />
      <ReportCardBar />
      <ReportChartBar colourScale={colourScale}/>
      <ReportChartBar2 colourScale={colourScale}/>
    </div>
  )
}

export default Report
