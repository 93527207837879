import React, {useContext} from 'react'
import PieChart from '../charts/PieChart.jsx'
import BarChart from '../charts/BarChart.jsx'
import { DataContext } from '../contexts/DataContext.jsx'
import { calculateTotalValues, calculateSumByProduct, extractLabels } from '../scripts/functions'

const ReportChartBar2 = ({colourScale}) => {
  const { state } = useContext(DataContext);
  const { filteredSalesData } = state;

  // Pie Chart
  const pTitle = 'Sales Comparison by Product'
  const pData = calculateSumByProduct(filteredSalesData)
  const key = extractLabels(pData);
  const pMargin = 5;
  const pOffSet = -100;

  // Bar Chart
  const bTitle = "Sales by Distributor";
  const bData = calculateTotalValues(filteredSalesData);
  const bMargin = { top: 10, right: 40, bottom: 30, left: 75 };
  const bPadding = 0.1;
  const maxBars = 10;

  return (
    <div className='reportchartbar2'>
      <PieChart 
        title={pTitle}
        data={pData}
        colourScale={colourScale}
        colourLegend="color-legend-left"
        legendItem="legend-item-left"
        legendLabel="legend-label"
        keys={key}
        margin={pMargin}
        backgroundColour="#F0E19959"
        offset={pOffSet}
      />
      <BarChart 
        title={bTitle}
        data={bData}
        margin={bMargin}
        maxBars={maxBars}
        padding={bPadding}
        backgroundColour="#F5C4AF59"
      />
    </div>
  )
}

export default ReportChartBar2