import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import ColourLegend from "../components/ColourLegend.jsx";
import ChartHeader from "../components/ChartHeader.jsx";

const PieChart = ({
  data,
  title,
  colourScale,
  keys,
  colourLegend,
  legendItem,
  legendLabel,
  margin,
  offset,
  backgroundColour,
}) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (data && svgRef.current) {
      const svg = d3
        .select(svgRef.current)
      const handleResize = () => {
        svg.selectAll(".pie").remove();
      const containerWidth = svg.node().parentNode.clientWidth;
      const containerHeight = svg.node().parentNode.clientHeight;
      const chartWidth = containerWidth - offset;
      const chartHeight = containerHeight;
      const radius = Math.min(chartWidth, chartHeight) / 2 - margin * 2;

      const pie = d3.pie().value((d) => d.value);

      const padAngle = 0.02
      const arc = d3
        .arc()
        .innerRadius(1)
        .outerRadius(radius)
        .padAngle(padAngle);
      // Calculate total value
      const totalValue = d3.sum(data, (d) => d.value);
      // Append the chart group
      const chart = svg
        .append("g")
        .attr("class", "pie")
        .attr(
          "transform",
          `translate(${chartWidth * 0.5}, ${chartHeight * 0.55})`
        );
      const slices = chart
        .selectAll("path")
        .data(pie(data))
        .enter()
        .append("path")
        .attr("class", "slice")
        .attr("d", arc)
        .attr("fill", (d, i) => colourScale(keys[i]))
        .on("mouseover", function(event, d) {
          const offset = 10;
          const centroid = arc.centroid(d);
          const x = centroid[0] + offset;
          const y = centroid[1];
        
          // Create a group for the label and value text and rectangle
          const tooltip = chart.append("g").attr("class", "tooltip");
        
          // Calculate the width and height based on text length
          const labelWidth = d.data.label.length * 8; 
          const valueWidth = (d.data.value * 100).toFixed(2).length * 8; 
          const maxWidth = Math.max(labelWidth, valueWidth);
          const widthPadding = 20; 
          const rectWidth = maxWidth + widthPadding;
          const rectHeight = 60; 
        
          // Create a rectangle background
          tooltip
            .append("rect")
            .attr("class", "tooltip-box")
            .attr("x", x - rectWidth / 2) 
            .attr("y", y - rectHeight / 2) 
            .attr("width", rectWidth)
            .attr("height", rectHeight)
            .attr("rx", 2.5)
            .attr("ry", 2.5)
            .attr("fill", "rgba(255, 255, 255, 0.9)");
        
          // Display the label text as justified
          tooltip
            .append("text")
            .attr("class", "percentage-label")
            .attr("x", x - rectWidth / 2 + 10) 
            .attr("y", y - rectHeight / 2 + 20) 
            .style("text-anchor", "start") 
            .style("font-family", "Arial")
            .style("font-weight", "bold")
            .style("font-size", "14px") 
            .text(d.data.label);
        
          // Display the value text as justified
          tooltip
            .append("text")
            .attr("class", "percentage-value")
            .attr("x", x - rectWidth / 2 + 10) 
            .attr("y", y + 5) 
            .style("text-anchor", "start") 
            .style("font-family", "Arial")
            .style("font-weight", "normal")
            .style("font-size", "11px") 
            .text(`${((d.data.value / totalValue) * 100).toFixed(2)}%`);
        })
        .on("mouseout", function() {
          chart.select(".tooltip").remove();
        });        

      // Animate the pie when the data changes
      slices
        .transition()
        .duration(500)
        .attrTween("d", function(d) {
          const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
          return function(t) {
            return arc(interpolate(t));
          };
        });
      }
      handleResize();

      window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
      svg.selectAll("*").remove();
    };
    }
  }, [margin, data]);

  return (
    <div
      className="pchart"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <ChartHeader title={title} />
      <ColourLegend
        keys={keys}
        colourScale={colourScale}
        colourLegend={colourLegend}
        legendItem={legendItem}
        legendLabel={legendLabel}
      />
      <svg className="chartsvg">
        <g className="pie" ref={svgRef} />
      </svg>
    </div>
  );
};

export default PieChart;
