import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../contexts/DataContext.jsx";
import { fetchInitData } from "../scripts/functions.js";
import { prodApi } from "./api.js";
import { useLoading } from "../contexts/LoadingContext.jsx";

// URLs
const urlSales = prodApi.salesData;
const urlStock = prodApi.stockData;
const urlTarget = prodApi.salesTarget

// Full Data
const EpiData = () => {
  const { dispatch } = useContext(DataContext);
  const { startLoading, stopLoading } = useLoading()

  const [salesData, setSalesData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [targetData, setTargetData] = useState([]);

  // FETCH AND SET DATA
  useEffect(() => {
    startLoading();
    fetchInitData(urlSales, setSalesData, 'branch');
    fetchInitData(urlStock, setStockData, 'branch');
    fetchInitData(urlTarget, setTargetData, 'branch')
      .then(() => {
        stopLoading();
      })
      .catch(() => {
        stopLoading();
      });
  }, []);

  useEffect(() => {
      dispatch({
        type: "SALES_DATA",
        payload: salesData,
      });
      dispatch({
        type: "STOCK_DATA",
        payload: stockData,
      });
      dispatch({
        type: "TARGET_DATA",
        payload: targetData,
      });
  },[
      salesData,
  ])

  return null;
};

export default EpiData;