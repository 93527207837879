import axios from "axios";

// Addition Function
const sumValuesByProduct = (data, product) => {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].product === product) {
      sum += parseInt(data[i].value);
    }
  }
  return sum;
};

// Thousand and Million Format
const formatNumber = (number) => {
  if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2) + "M";
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(2) + "K";
  } else {
    return number.toString();
  }
};

// Unique Options Function
function getUniqueValues(array, key) {
  return [...new Set(array.map((item) => item[key]))];
}

// Distinct Count
function getDistinctCount(data, key) {
  const distinctValues = new Set();
  for (let i = 0; i < data.length; i++) {
    distinctValues.add(data[i][key]);
  }
  return distinctValues.size;
}

// Addition by Distributor
const sumValuesByDistributor = (data, distributor) => {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].distributor === distributor) {
      sum += parseInt(data[i].value);
    }
  }
  return sum;
};

// Distributor Totals
const calculateTotalValues = (data) => {
  data.sort((a, b) => b.value - a.value);
  const totals = {};

  for (let i = 0; i < data.length; i++) {
    const entry = data[i];
    const { distributor, product, value } = entry;

    let multiplier;
    if (product === "Polygard Kilit (400ml)") {
      multiplier = 24;
    } else if (product === "Polygard Kilit (750ml)") {
      multiplier = 12;
    } else if (product === "Kilit Coil") {
      multiplier = 60;
    } else {
      // Ignore other product types
      continue;
    }

    const adjustedValue = +value * multiplier;

    if (totals[distributor]) {
      totals[distributor] += adjustedValue;
    } else {
      totals[distributor] = adjustedValue;
    }
  }

  return totals;
};

// Multibar Chart Separation Logic
const processData = (data) => {
  // data.sort((a, b) => b.value - a.value);

  const multipliers = {
    "Polygard Kilit (400ml)": 24,
    "Polygard Kilit (750ml)": 12,
    "Kilit Coil": 60,
  };

  const uniqueMonths = [...new Set(data.map((item) => item.month))];
  const uniqueProducts = [...new Set(data.map((item) => item.product))];
  const monthValues = {};

  data.forEach((item) => {
    const { month, product, value } = item;
    const multiplier = multipliers[product] || 1;
    const multipliedValue = +value * multiplier;

    if (monthValues[month]) {
      monthValues[month][product] =
        (monthValues[month][product] || 0) + multipliedValue;
    } else {
      monthValues[month] = { [product]: multipliedValue };
    }
  });

  const productValues = Array.from(uniqueProducts).map((product) => {
    const values = Object.values(monthValues).map(
      (products) => products[product] || 0
    );
    return values;
  });

  const result = [
    Array.from(uniqueMonths),
    Array.from(uniqueProducts),
    productValues,
  ];

  return result;
};

const calculateSumByBranch = (data) => {
  const multipliedValues = {};

  data.forEach((item) => {
    const { branch, product, value } = item;
    let multiplier;

    if (product === "Polygard Kilit (400ml)") {
      multiplier = 24;
    } else if (product === "Polygard Kilit (750ml)") {
      multiplier = 12;
    } else if (product === "Kilit Coil") {
      multiplier = 60;
    }

    if (multiplier) {
      if (multipliedValues[branch]) {
        multipliedValues[branch] += value * multiplier;
      } else {
        multipliedValues[branch] = value * multiplier;
      }
    }
  });

  const result = Object.entries(multipliedValues).map(([branch, value]) => ({
    label: branch,
    value: value,
  }));

  return result;
};

const calculateSumByProduct = (data) => {
  const multipliedValues = {};

  data.forEach((item) => {
    const { product, value } = item;
    let multiplier;

    if (product === "Polygard Kilit (400ml)") {
      multiplier = 24;
    } else if (product === "Polygard Kilit (750ml)") {
      multiplier = 12;
    } else if (product === "Kilit Coil") {
      multiplier = 60;
    }

    if (multiplier) {
      if (multipliedValues[product]) {
        multipliedValues[product] += value * multiplier;
      } else {
        multipliedValues[product] = value * multiplier;
      }
    }
  });

  const result = Object.entries(multipliedValues).map(([product, value]) => ({
    label: product,
    value: value,
  }));

  return result;
};

const extractLabels = (data) => {
  return data.map((item) => item.label);
};

const fetchData = async (url, setAData, setFilteredAData) => {
  try {
    const res = await axios.get(url);
    const data = res.data;
    setAData(
      data.map((item) => ({
        ...item,
        year: item.date.split("-")[0],
        month: new Date(item.date).toLocaleString("default", {
          month: "short",
        }),
      }))
    );
    setFilteredAData(data);
  } catch (err) {
    console.error("Error retrieving data:", err);
  }
};

// Helper function to get the quarter based on the month
const getQuarter = (month) => {
  if (month >= 0 && month <= 2) {
    return "First";
  } else if (month >= 3 && month <= 5) {
    return "Second";
  } else if (month >= 6 && month <= 8) {
    return "Third";
  } else {
    return "Fourth";
  }
};

const fetchInitData = async (url, setData, sortBy) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await res.data;

    // Sort the data by date
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Then, sort the data by LGA in alphabetical order
    data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));

    setData(
      data.map((item) => {
        const date = new Date(item.date);
        const year = date.getFullYear();
        const month = date.getMonth();
        const quarter = getQuarter(month);

        return {
          ...item,
          year,
          month: new Date(item.date).toLocaleString("default", {
            month: "short",
          }),
          quarter: quarter,
        };
      })
    );
  } catch (err) {
    console.error("Error retrieving data:", err);
    throw err;
  }
};

const sortMonths = (monthsArray) => {
  // Define an object to map month names to their numeric values
  const monthToNumber = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  // Sort the array based on the numeric values of the months
  monthsArray.sort((a, b) => monthToNumber[a] - monthToNumber[b]);

  return monthsArray;
};

const sortQuarters = (quarterArray) => {
  // Define an object to map month names to their numeric values
  const quarterToNumber = {
    First: 1,
    Second: 2,
    Third: 3,
    Fourth: 4,
  };

  // Sort the array based on the numeric values of the months
  quarterArray.sort((a, b) => quarterToNumber[a] - quarterToNumber[b]);

  return quarterArray;
};

const sortYears = (years) => {
  return years.sort((a, b) => a - b);
};

const sortAlphabetically = (arr) => {
  // Use the sort method with a comparison function to sort alphabetically
  arr.sort(function(a, b) {
    return a.localeCompare(b);
  });

  return arr;
};

const formatNumberWithCommas = (number) => {
  const numberStr = number.toString();
  const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
};

export {
  sumValuesByProduct,
  formatNumber,
  getUniqueValues,
  getDistinctCount,
  sumValuesByDistributor,
  calculateTotalValues,
  processData,
  calculateSumByBranch,
  calculateSumByProduct,
  extractLabels,
  fetchData,
  fetchInitData,
  sortMonths,
  sortQuarters,
  sortYears,
  sortAlphabetically,
  formatNumberWithCommas,
};
