import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const Guage = ({
  margin,
  cornerRadius,
  textOffset,
  guageLevel,
  guageLevelText,
  guageTarget,
  filteredSalesData,
  filteredSalesTarget,
  backgroundColour,
  innerRadiusMultiplier = 0.6,
  total = Math.PI * 2,
}) => {
  const svgRef = useRef(null);

  useEffect(() => {
    if (guageLevel && svgRef.current) {
      const svg = d3
        .select(svgRef.current)
      const handleResize = () => {
        svg.selectAll("*").remove();

        const containerWidth = svg.node().parentNode.clientWidth;
        const containerHeight = svg.node().parentNode.clientHeight;

        const width = containerWidth;
        const height = containerHeight;
        const radius = Math.min(width, height) / 2 - margin * 2;

        const chart = svg
          .append("g")
          .attr("class", "gauge")
          .attr("transform", `translate(${width / 2}, ${height / 2})`);

        const fullArc = d3
          .arc()
          .innerRadius(radius * innerRadiusMultiplier)
          .outerRadius(radius)
          .startAngle(0)
          .endAngle(total);

        const levelArc = d3
          .arc()
          .innerRadius(radius * innerRadiusMultiplier + margin / 2)
          .outerRadius(radius - margin / 2)
          .cornerRadius(cornerRadius)
          .startAngle(0)
          .endAngle(guageLevel);

        chart
          .append("path")
          .attr("class", "full-arc")
          .attr("d", fullArc)
          .attr("fill", "rgba(0,0,0,0.15)");

        chart
          .append("path")
          .attr("class", "half-arc")
          .attr("d", levelArc)
          .attr("fill", "#118DFF");

        chart
          .append("svg:text")
          .attr("class", "middle-text")
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .text(guageLevelText);

        chart
          .append("text")
          .attr("class", "left-text")
          .attr("text-anchor", "end")
          .attr("alignment-baseline", "middle")
          .text("0.00")
          .attr("x", -radius * textOffset)
          .attr("y", 0);
  
        chart
          .append("text")
          .attr("class", "right-text")
          .attr("text-anchor", "start")
          .attr("alignment-baseline", "middle")
          .text(guageTarget)
          .attr("x", radius * textOffset)
          .attr("y", 0);

        return () => {
        svg.selectAll("*").remove();
        };
      }
      handleResize();

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        svg.selectAll("*").remove();
      };
    }
  }, [margin, guageLevel, filteredSalesTarget, filteredSalesData]);

  return (
    <div 
      className="guage"
      style={{
        backgroundColor: `${backgroundColour}`,
      }}
    >
      <svg className="guagesvg" ref={svgRef}></svg>
    </div>
  );
};

export default Guage;
