import * as d3 from "d3";

const blue = "#118DFF";
const darkblue = "#12239E";
const orange = "#E66C37";
const purple = "#6B007B";

const colourScale = d3.scaleOrdinal().range([
  blue,
  darkblue,
  orange,
  purple,
  // "#118DFF",
  // "#12239E",
  // "#744EC2",
  // "#6B007B",
  // "#D64550",
  // "#407855",
  // "#E044A7",
  // "#A42626",
  // "#40C5AF",
  // "#E377C2",
  // "#197278",
  // "#1AAB40",
  // "#15C6F4",
  // "#BE5DC9",
]);

export { colourScale };
