import React, {useContext} from 'react'
import Card from '../charts/Card.jsx'
import Guage from '../charts/Guage.jsx'
import {DataContext} from '../contexts/DataContext.jsx'
import { sumValuesByProduct, formatNumber, getDistinctCount } from '../scripts/functions.js'

const ReportCardBar = () => {
  const { state } = useContext(DataContext);
  const { 
    filteredStockData,
    salesEstimateData,
    distributorSelectFilter,
    filteredSalesData,
    productSelectFilter,
    filteredSalesTarget, 
  } = state

  // CARDS
  // Data Functions
  const kCoil = "Kilit Coil";
  const k400 = "Polygard Kilit (400ml)";
  const k750 = "Polygard Kilit (750ml)";

  // Unit Conversion
  const kCoilMultiplier = 60;
  const k400Multiplier = 24;
  const k750Multiplier = 12;

  // Sales Units
  const kilitCoilSalesUnits =
    sumValuesByProduct(productSelectFilter, kCoil) * kCoilMultiplier;
  const kilit400SalesUnits =
    sumValuesByProduct(productSelectFilter, k400) * k400Multiplier;
  const kilit750SalesUnits =
    sumValuesByProduct(productSelectFilter, k750) * k750Multiplier;

  // Sales Units for KPI
  const kilitCoilSalesUnitsKPI =
    sumValuesByProduct(filteredSalesData, kCoil) * kCoilMultiplier;
  const kilit400SalesUnitsKPI =
    sumValuesByProduct(filteredSalesData, k400) * k400Multiplier;
  const kilit750SalesUnitsKPI =
    sumValuesByProduct(filteredSalesData, k750) * k750Multiplier;

  // Sales Units (for Stock Calculation)
  const kilitCoilEstimatedUnits =
    sumValuesByProduct(salesEstimateData, kCoil) * kCoilMultiplier;
  const kilit400EstimatedUnits =
    sumValuesByProduct(salesEstimateData, k400) * k400Multiplier;
  const kilit750EstimatedUnits =
    sumValuesByProduct(salesEstimateData, k750) * k750Multiplier;

  // Stock Units
  const kilitCoilStockUnits =
    sumValuesByProduct(filteredStockData, kCoil) * kCoilMultiplier;
  const kilit400StockUnits =
    sumValuesByProduct(filteredStockData, k400) * k400Multiplier;
  const kilit750StockUnits =
    sumValuesByProduct(filteredStockData, k750) * k750Multiplier;

  // Total Sales for KPI
  const totalSalesUnitsKPI =
    kilitCoilSalesUnitsKPI + kilit400SalesUnitsKPI + kilit750SalesUnitsKPI;

 

  // Sales Target Units
  const kilitCoilSalesTargetUnits = sumValuesByProduct(
    filteredSalesTarget,
    kCoil
  );
  const kilit400SalesTargetUnits = sumValuesByProduct(
    filteredSalesTarget,
    k400
  );
  const kilit750SalesTargetUnits = sumValuesByProduct(
    filteredSalesTarget,
    k750
  );

  // Total Target
  const totalTargetUnits =
    kilitCoilSalesTargetUnits +
    kilit400SalesTargetUnits +
    kilit750SalesTargetUnits;

  // Card Logic
  // Sales
  const kilitCoilSales = formatNumber(kilitCoilSalesUnits);
  const kilit400Sales = formatNumber(kilit400SalesUnits);
  const kilit750Sales = formatNumber(kilit750SalesUnits);

  // Distributor
  const distributorCountValue = getDistinctCount(
    distributorSelectFilter,
    "distributor"
  ).toString();

  // Stock
  const totalStock =
    kilitCoilStockUnits + kilit400StockUnits + kilit750StockUnits;
  const totalEstimatedSale =
    kilitCoilEstimatedUnits + kilit400EstimatedUnits + kilit750EstimatedUnits;
  const totalStockValue = totalStock - totalEstimatedSale;
  const stockValue = formatNumber(totalStockValue);

  // KPI Chart Logic
  const margin = 5;
  const cornerRadius = 5;
  const total = Math.PI * 2;
  const textOffset = 1.2;

  const guageLevel = (totalSalesUnitsKPI / totalTargetUnits) * total;
  const guageLevelText = formatNumber(totalSalesUnitsKPI);
  const guageTarget = formatNumber(totalTargetUnits);

  const grey = "rgba(0,0,0,0.1)"

  return (
    <div className='reportcardbar'>
      <Guage
        margin={margin}
        cornerRadius={cornerRadius}
        total={total}
        textOffset={textOffset}
        guageLevel={guageLevel}
        guageLevelText={guageLevelText}
        guageTarget={guageTarget}
        backgroundColour={grey}
      />
      <Card
        subtitle="Kilit Coil Units"
        value={kilitCoilSales}
        backgroundColor={grey}
      />
      <Card
        subtitle="Polygard Kilit (400ml) Units"
        value={kilit400Sales}
        backgroundColor={grey}
      />
      <Card
        subtitle="Polygard Kilit (750ml) Units"
        value={kilit750Sales}
        backgroundColor={grey}
      />
      <Card
        subtitle="Number of Distributors"
        value={distributorCountValue}
        backgroundColor={grey}
      />
      <Card
        subtitle="Stock"
        value={stockValue}
        backgroundColor={grey}
      />
    </div>
  )
}

export default ReportCardBar