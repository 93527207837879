import React from 'react'

const Report = () => {
  return (
    <div className='kpidashboard'>
      KPIs
    </div>
  )
}

export default Report